@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("../plugins/bain/style.css");
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../plugins/fontawesome/css/all.min.css");
@media (min-width: 1900px) {
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 19%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 640px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 18.5%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 18.5%;
  }
}
@media (min-width: 1800px) and (max-width: 1899px) {
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 17%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 620px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 17%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 17%;
  }
}
@media (min-width: 1700px) and (max-width: 1799px) {
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 15%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 600px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 15%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 15%;
  }
}
@media (min-width: 1700px) {
  .cs-slider-1 .logo-m-1 {
    right: -120px;
  }
}
@media (min-width: 1500px) and (max-width: 1599px) {
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 11.5%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 540px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 11.5%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 11.5%;
  }
}
@media (min-width: 1400px) {
  .container-2 {
    max-width: 1390px;
  }
}
@media (min-width: 1400px) and (max-width: 1499px) {
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 8.5%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 500px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 8.5%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 8.5%;
  }
}
@media (min-width: 1366px) {
  .cs-slider-1 .slider-container-1 {
    height: 400px;
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .cs-main-menu-1 .main-li-1 {
    margin-right: 12px;
  }
  .cs-main-menu-1 .main-li-1 .main-a-1 {
    padding: 32px 7px;
    font-size: 15px;
  }
  .cs-slider-1 .slider-container-2 {
    background-position: 120% 100% !important;
  }
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 6.5%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 480px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 6.5%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 6.5%;
  }
}
@media (min-width: 1260px) {
  .container {
    max-width: 1230px;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .cs-header-row-1 .cshr1-col-1 {
    flex: 0 0 225px;
    max-width: 225px;
  }
  .cs-header-row-1 .cshr1-col-2 {
    flex: 0 0 calc(100% - 600px);
    max-width: calc(100% - 600px);
  }
  .cs-header-row-1 .cshr1-col-3 {
    flex: 0 0 375px;
    max-width: 375px;
  }
  .cs-main-menu-1 .main-li-1 {
    margin-right: 12px;
  }
  .cs-main-menu-1 .main-li-1 .main-a-1 {
    padding: 32px 7px;
    font-size: 14px;
  }
  .cs-slider-1 .slider-container-2 {
    background-position: 140% 100% !important;
  }
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 3.5%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 450px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-1 {
    font-size: 38px;
    line-height: 46px;
  }
  .cs-soon-top-s-1 {
    margin-bottom: 50px;
  }
  .cs-soon-top-s-1 .head-1 {
    font-size: 62px;
    line-height: 72px;
  }
}
@media (min-width: 1100px) and (max-width: 1366px) {
  .cs-slider-1 .slider-container-1 {
    height: 350px;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .cs-main-menu-1 .main-li-1 {
    margin-right: 12px;
  }
  .cs-main-menu-1 .main-li-1 .main-a-1 {
    padding: 32px 7px;
    font-size: 14px;
  }
  .cs-header-row-1 .cshr1-col-1 {
    flex: 0 0 225px;
    max-width: 225px;
  }
  .cs-header-row-1 .cshr1-col-2 {
    flex: 0 0 calc(100% - 600px);
    max-width: calc(100% - 600px);
  }
  .cs-header-row-1 .cshr1-col-3 {
    flex: 0 0 375px;
    max-width: 375px;
  }
  .cs-main-menu-1 .main-li-1 {
    margin-right: 12px;
  }
  .cs-slider-1 .slider-container-2 {
    background-position: 140% 100% !important;
  }
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 2.5%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 430px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    padding-left: 2.5%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 2.5%;
  }
  .cs-soon-top-s-1 {
    margin-bottom: 50px;
  }
  .cs-soon-top-s-1 .head-1 {
    font-size: 62px;
    line-height: 72px;
  }
}
@media (min-width: 1100px) {
  .cs-header-row-2 .cshr2-col-3 {
    display: none;
  }
}
@media (max-width: 1100px) {
  .mobile-overflow-h {
    overflow: hidden;
  }
  .cs-header-row-2 .cshr2-col-1 {
    flex: 0 0 65px;
    max-width: 65px;
  }
  .cs-header-row-2 .cshr2-col-2 {
    flex: 0 0 calc(100% - 275px);
    max-width: calc(100% - 275px);
  }
  .cs-header-row-2 .cshr2-col-3 {
    flex: 0 0 70px;
    max-width: 70px;
  }
  .cs-sticky-header-1 {
    padding: 12px 0px;
  }
}
@media (min-width: 1024px) and (max-width: 1100px) {
  #goTpButton {
    bottom: 60px;
    right: 5px;
  }
  .cs-header-row-1 .cshr1-col-1 {
    flex: 0 0 calc(100% - 475px);
    max-width: calc(100% - 475px);
  }
  .cs-header-row-1 .cshr1-col-2 {
    display: none;
  }
  .cs-header-row-1 .cshr1-col-3 {
    flex: 0 0 475px;
    max-width: 475px;
  }
  .cs-footer-row-1 .csfr1-col-1 {
    margin-bottom: 30px;
  }
  .cs-footer-row-1 .csfr1-col-2 {
    flex: 0 0 calc((100% - 300px) / 3);
    max-width: calc((100% - 300px) / 3);
    margin-bottom: 30px;
  }
  .cs-slider-1 .slider-container-2 {
    background-position: 150% 100% !important;
    background-size: 82% !important;
  }
  .cs-slider-1 .slider-container-2 .container-2 {
    margin-bottom: 150px;
  }
  .cs-slider-1 .slider-container-1 {
    height: 300px !important;
  }
  .cs-loc-date-d-1 .csld1-col-1 {
    flex: 0 0 280px;
    max-width: 280px;
  }
  .cs-loc-date-d-1 .csld1-col-2 {
    flex: 0 0 490px;
    max-width: 490px;
  }
  .cs-wbg-head-2 {
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 30px;
  }
  .cs-slider-row-1 .cssr1-col-2 {
    padding-left: 50px;
  }
  .cs-slider-row-1 .cssr1-col-3 {
    padding-right: 50px;
  }
  .cs-slider-cont-1 .bg-image-1 {
    right: -35px;
  }
  .cs-slider-cont-1 .bg-image-1.right {
    left: -35px;
  }
  .cs-navTabs-1.nav-tabs .nav-link {
    font-size: 18px;
    line-height: 26px;
    padding: 15px;
  }
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 4.5%;
  }
  .cs-homepage-sec-7 .cs-slider-4.owl-theme .owl-nav {
    top: -170px;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 400px;
  }
  .cs-homepage-sec-10 .slider-content-1 {
    padding-top: 100px;
  }
  .cs-login-main-row-1 .cslmr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-login-main-row-1 .cslmr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-login-bg-div-1 {
    min-height: 45vh;
  }
  .cs-login-form-c-1 {
    margin: 0 auto;
    padding: 50px 0px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-1 {
    font-size: 25px;
    line-height: 33px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-2 {
    font-size: 16px;
    line-height: 24px;
  }
  .cs-syc-sec-3 .cs-syc-3-box-main {
    grid-template-columns: repeat(2, 1fr);
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 {
    right: 0;
    max-width: 500px;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 {
    left: 0;
    max-width: 500px;
  }
  .cs-rentcar-row-1 .csrcr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    min-height: 1px;
  }
  .cs-rentcar-row-1 .csrcr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    height: 350px;
  }
  .cs-page-filter-d-1 .inner-f-div-1 {
    margin: 0 auto;
  }
  .cs-page-list-1 {
    margin: 0 auto;
  }
  .cs-page-list-1 .inner-pl-1 {
    max-height: calc(100vh - 660px);
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-middle-area-1 {
    max-height: 500px;
    min-height: 500px;
  }
  .cs-search-locDate-area-1 .inn-cont-1 {
    max-width: 930px;
  }
  .cs-orderSum-row-1 .csorr1-col-1 {
    flex: 0 0 calc(100% - 480px);
    max-width: calc(100% - 480px);
  }
  .cs-orderSum-row-1 .csorr1-col-2 {
    flex: 0 0 480px;
    max-width: 480px;
  }
  .cs-account-earning-d-1 {
    right: 3rem;
  }
  .cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-image-box-1 img {
    width: 450px;
  }
  .cs-icon-box-2 .csib2-desc-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-icon-box-2 .csib2-desc-2 {
    font-size: 20px;
    line-height: 28px;
  }
  .cs-account-cars-row-1 .csacr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-cars-row-1 .csacr1-col-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cs-account-cars-row-1 .csacr1-col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cs-account-cars-search-1 {
    max-width: 100%;
  }
  .cs-account-cars-status-m-1 button {
    min-width: 90px;
  }
  .cs-account-cars-row-2 .csacr2-col-1 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  #stop_share_modal .cs-padding-1 {
    padding: 0 5% !important;
  }
  .cs-form-1-2 .date-box-1 label:before {
    left: 43px !important;
  }
  #car-photo-add-modal .modal-dialog, #damage_area_modal .modal-dialog {
    max-width: fit-content;
  }
  .cs-cars-upload-list-1 .cscul1-col-1 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 {
    gap: 45px;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options {
    justify-content: flex-start;
  }
  .cs-soon-top-s-1 .head-1 {
    font-size: 60px;
    line-height: 70px;
  }
}
@media (min-width: 1024px) {
  .cs-account-mobile-d-1 {
    display: none;
  }
}
@media (max-width: 1023px) {
  .cs-account-mobile-d-1 {
    display: block;
  }
  .cs-account-mobile-block {
    transition: max-height 0.66s cubic-bezier(0.52, 0.16, 0.24, 1);
    max-height: 0vh;
    overflow: hidden;
  }
  .cs-account-mobile-block.active {
    max-height: 1000vh;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #goTpButton {
    bottom: 60px;
    right: 5px;
  }
  .cs-header-row-1 .cshr1-col-1 {
    flex: 0 0 calc(100% - 475px);
    max-width: calc(100% - 475px);
  }
  .cs-header-row-1 .cshr1-col-2 {
    display: none;
  }
  .cs-header-row-1 .cshr1-col-3 {
    flex: 0 0 475px;
    max-width: 475px;
  }
  .cs-footer-row-1 .csfr1-col-1 {
    margin-bottom: 30px;
  }
  .cs-footer-row-1 .csfr1-col-2 {
    flex: 0 0 calc((100% - 300px) / 2);
    max-width: calc((100% - 300px) / 2);
    margin-bottom: 30px;
  }
  .cs-slider-1 .slider-container-2 {
    background-position: 150% 100% !important;
    background-size: 88% !important;
  }
  .cs-slider-1 .slider-container-2 .container-2 {
    margin-bottom: 150px;
  }
  .cs-slider-1 .slider-container-1 {
    height: 275px !important;
  }
  .cs-homepage-sec-2 {
    margin-bottom: 40px;
  }
  .cs-loc-date-d-1 .csld1-col-1 {
    flex: 0 0 250px;
    max-width: 250px;
  }
  .cs-loc-date-d-1 .csld1-col-2 {
    flex: 0 0 438px;
    max-width: 438px;
  }
  .cs-loc-date-d-1 .csld1-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .cs-loc-date-d-1 .csld1-col-3 .cs-secondary-btn-1 {
    width: 100%;
  }
  .cs-slider-row-1 .cssr1-col-1 {
    flex: 0 0 250px;
    max-width: 250px;
    margin: 0 auto;
  }
  .cs-slider-row-1 .cssr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
  }
  .cs-slider-row-1 .cssr1-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    order: 1;
  }
  .cs-slider-cont-1 .bg-image-1 {
    right: -35px;
  }
  .cs-navTabs-1.nav-tabs .nav-link {
    font-size: 18px;
    line-height: 26px;
    padding: 15px;
  }
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 5.5%;
  }
  .cs-homepage-sec-7 .cs-slider-4.owl-theme .owl-nav {
    top: -220px;
  }
  .cs-download-app-row-1 .csdar1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-download-app-row-1 .csdar1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 470px;
  }
  .cs-campaign-box-1 .text-div-1 .head-1 {
    font-size: 20px;
    line-height: 30px;
    max-height: 30px;
    min-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .cs-blog-content-row-1 .csbcr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-blog-content-row-1 .csbcr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-homepage-sec-10 .slider-content-1 {
    padding-top: 85px;
  }
  .cs-blog-box-1 .image-div-1 {
    background-size: cover !important;
  }
  .cs-login-main-row-1 .cslmr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-login-main-row-1 .cslmr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-login-bg-div-1 {
    min-height: 45vh;
  }
  .cs-login-form-c-1 {
    margin: 0 auto;
    padding: 50px 0px;
  }
  .cs-signal-cont-1 .cssc1-d-1 .cssc1d1-inn-1 {
    right: -25vw;
  }
  .cs-rentcar-row-1 .csrcr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    min-height: 1px;
  }
  .cs-rentcar-row-1 .csrcr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    height: 275px;
  }
  .cs-page-filter-d-1 .inner-f-div-1 {
    margin: 0 auto;
  }
  .cs-page-list-1 {
    margin: 0 auto;
  }
  .cs-page-list-1 .inner-pl-1 {
    max-height: 85vh;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-middle-area-1 {
    max-height: 500px;
    min-height: 500px;
  }
  .cs-search-locDate-area-1 .inn-cont-1 {
    padding: 0px 15px;
  }
  .cs-orderSum-row-1 .csorr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 25px;
  }
  .cs-orderSum-row-1 .csorr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-m-row-1 .csamr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-m-row-1 .csamr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-address-box-1 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .cs-account-earning-d-1 {
    right: 2.5rem;
  }
  .cs-icon-box-2 .csib2-desc-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-icon-box-2 .csib2-desc-2 {
    font-size: 20px;
    line-height: 28px;
  }
  .cs-account-cars-row-1 .csacr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-cars-row-1 .csacr1-col-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cs-account-cars-row-1 .csacr1-col-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cs-account-cars-search-1 {
    max-width: 100%;
  }
  .cs-account-cars-status-m-1 button {
    min-width: 100px;
  }
  .cs-profile-detail-wp-left {
    padding: 25px !important;
  }
  .cs-modal-5 .cs-damage-photos-main {
    flex-wrap: wrap;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-left .cs-g-file-main {
    margin: 0 auto !important;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-right .cs-g-file-main {
    margin: 0 auto !important;
  }
  .cs-cars-wp-row-1 .cscwpr1-col-1, .cs-cars-wp-row-1 .cscwpr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-soon-top-s-1 .head-1 {
    font-size: 62px;
    line-height: 72px;
  }
  /************ frontend-ukbe-responsive tablet start ************/
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 {
    padding: 25px 0;
  }
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 {
    display: flex;
    padding: 10px 0;
    gap: 17px;
    flex-direction: column;
    align-items: center;
  }
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1:last-child {
    flex-direction: column-reverse;
  }
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 .cs-about-us-imagebox-1 {
    flex: 1;
  }
  .cs-career-sec-2 .cs-career-advert-itembox-1 .cs-career-advert-item {
    border-radius: 25px !important;
  }
  .cs-career-sec-2 .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-info-1 {
    flex-direction: column;
    gap: 10px;
  }
  .cs-career-sec-2 .cs-career-advert-itembox-1 .cs-career-advert-item .cs-item-info-1 span.cs-desc-1 {
    text-align: left !important;
  }
  .cs-career-detail-sec-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 .cs-item-info-1 {
    display: flow-root;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 {
    flex-direction: column;
  }
  .cs-car-detail-sec-1 .cs-cd-infobox-1 .cs-cd-feature-1 {
    flex-wrap: wrap;
  }
  /************ frontend-ukbe-responsive tablet end ************/
  .cs-form-1-2 .date-box-1 label:before {
    left: 42px !important;
  }
  #stop_share_modal .modal-content {
    width: 100% !important;
  }
  #stop_share_modal .cs-padding-1 {
    padding: 0 5% !important;
  }
  .cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main {
    gap: 20px;
  }
  .cs-p-arabanipaylas-sec-main .cs-arabani-pay-bry-1-main .cs-image-box-1 img {
    width: 300px;
  }
  .cs-p-arabanipaylas-sec-main .cs-primary-checkbox-1 .cs-primary-label {
    font-size: 14px;
  }
  .cs-car-detail-main-area .cs-car-main {
    grid-template-columns: repeat(3, 1fr);
  }
  .cs-p-vehicle-arrangement {
    padding: 0 20px;
  }
  .cs-p-campaign-sec-1 .cs-sec-campaign-main {
    flex-direction: column;
  }
  .cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox {
    order: 2;
  }
  .cs-p-blog-sec-1 {
    padding: 0;
  }
  .cs-p-blogdetail-sec-1 .cs-blog-detail-image {
    max-width: 100%;
  }
  .cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main {
    margin-bottom: 100px;
  }
  .cs-syc-sec1 .cs-syc-sec-1-title {
    text-align: center;
    margin-bottom: 25px;
  }
  .cs-syc-sec1 .cs-syc-1-main {
    margin: 30px 0;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-1 {
    font-size: 30px;
    line-height: 38px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn {
    margin-bottom: 20px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn .cs-primary-btn-1 {
    min-height: 52px;
    min-width: 100%;
    font-size: 20px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn .dropdown {
    width: 100%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn .dropdown .dropdown-menu {
    width: 100%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox {
    padding-left: 0;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    max-width: 660px;
    right: 13.5%;
  }
  .cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-title-banner-1 {
    font-size: 45px;
    line-height: 53px;
  }
  .cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-desc-banner-1 {
    font-size: 25px;
    line-height: 33px;
  }
  .cs-syc-sec-3 .cs-syc-3-box-main {
    grid-template-columns: repeat(2, 1fr);
  }
  .cs-syc-sec-4 {
    height: auto;
  }
  .cs-syc-sec-4 .cs-sec4-img1 {
    width: 100%;
    margin-bottom: 10px;
  }
  .cs-syc-sec-4 .cs-sec4-img2 {
    width: 100%;
    margin-bottom: 10px;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main {
    flex-direction: column;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 {
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 100%;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-wbg-head-2, .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-sys-sec4-title {
    text-align: center;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 {
    position: relative;
    top: 0;
    right: 0;
    order: 2;
    max-width: 100%;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-wbg-head-2, .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-sys-sec4-title {
    text-align: center;
  }
  .cs-damage-photos-main {
    flex-direction: column;
    align-items: center;
  }
  #car-photo-add-modal .modal-content, #damage_area_modal .modal-content {
    width: 100%;
  }
  .cs-create-mission-main {
    padding: 30px 15%;
  }
  .cs-location-modal {
    left: -20%;
  }
  .cs-rent-bill-main-1 .cs-rent-bill-content .cs-textbox-1 .cs-title-1 {
    font-size: 14px;
  }
  .cs-download-app-2 {
    position: revert;
    margin-top: 30px;
  }
  .cs-download-app-2 .image-m-1 {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  #goTpButton {
    width: 40px;
    height: 40px;
    bottom: 30px;
    right: 20px;
  }
  #goTpButton::after {
    font-size: 1.8em;
    line-height: 50px;
    position: relative;
    bottom: 5px;
  }
  .cs-header-row-1 .cshr1-col-1 {
    flex: 0 0 calc(100% - 270px);
    max-width: calc(100% - 270px);
  }
  .cs-header-row-1 .cshr1-col-2 {
    display: none;
  }
  .cs-header-row-1 .cshr1-col-3 {
    flex: 0 0 270px;
    max-width: 270px;
  }
  .cs-header-row-2 {
    justify-content: flex-end;
  }
  .cs-header-row-2 .cshr2-col-1 {
    flex: 0 0 45px;
    max-width: 45px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .cs-header-row-2 .cshr2-col-2 {
    flex: 0 0 45px;
    max-width: 45px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .cs-header-row-2 .cshr2-col-3 {
    flex: 0 0 45px;
    max-width: 45px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .cs-header-btn-1 {
    font-size: 20px;
    height: 35px;
  }
  .cs-header-btn-2 {
    font-size: 18px;
    height: 35px;
  }
  .cs-header-btn-3 {
    min-height: 35px;
  }
  .cs-header-btn-3::before {
    content: "\e92b";
    font-family: "bain" !important;
  }
  .cs-header-btn-3 .ds-in-1 {
    display: none;
    padding: 2px;
  }
  .cs-language-select {
    font-size: 19px;
    height: 35px;
  }
  .cs-header-btn-4 {
    min-height: 35px;
    font-size: 12px;
  }
  .cs-header-btn-4 .image-d-1 {
    border-radius: 22px;
    flex: 0 0 29px;
    max-width: 29px;
  }
  .cs-header-btn-4 .image-d-1 img {
    max-height: 30px;
    border-radius: 22px;
    object-fit: cover;
  }
  .cs-header-btn-4 .image-d-1 .fake-char-i-1 {
    height: 29px;
  }
  .cs-header-btn-4 .name-d-1 {
    display: none;
  }
  .cs-globe-dd-1 .csgdd1-d-1 {
    left: auto;
    right: 5px;
    width: 220px;
  }
  .cs-mobile-menu-d-1.active .image-area-1 .image-3 {
    left: -12vw;
  }
  .cs-mobile-menu-d-1 .image-area-1 .image-3 {
    height: 100px;
  }
  .cs-mobile-menu-d-1 .content-area-1 {
    max-height: 80vh;
  }
  .cs-globe-dd-1 .csgdd1-d-1 .csgdd1-m-1 .csgdd1-m-l-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-main-logo-1 img {
    max-height: 40px;
  }
  .cs-footer-row-1 .csfr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .cs-footer-row-1 .csfr1-col-2 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }
  .cs-footer-menu-2 {
    justify-content: center;
  }
  .cs-wbg-head-1 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .cs-footer-menu-1 li a {
    font-size: 16px;
    line-height: 22px;
  }
  .cs-slider-1.owl-theme .owl-nav {
    padding: 0px 15px;
    bottom: -5px;
    width: auto;
    left: 0;
    transform: unset;
  }
  .cs-slider-1.owl-theme .owl-nav button {
    font-size: 16px !important;
  }
  .cs-slider-1 .slider-content-1 {
    padding: 0;
    margin-bottom: 50px;
  }
  .cs-slider-1 .slider-container-1 {
    height: max-content;
    background-size: cover !important;
  }
  .cs-slider-1 .slider-container-2 {
    background-position: 100% 100% !important;
    background-size: 100% !important;
    height: 430px;
  }
  .cs-slider-1 .slider-container-2 .container-2 {
    margin-bottom: 150px;
  }
  .cs-slider-1 .logo-m-1 li img {
    max-width: 100px;
    min-height: 36px;
  }
  .cs-slider-1 .desc-1 {
    font-size: 20px;
    line-height: 26px;
  }
  .cs-slider-1 .desc-2 {
    font-size: 20px;
    line-height: 26px;
  }
  .cs-loc-date-d-1 .csld1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    border-color: transparent;
  }
  .cs-loc-date-d-1 .csld1-col-1.deactive {
    border-color: transparent;
  }
  .cs-loc-date-d-1 .csld1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-loc-date-d-1 .csld1-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .cs-loc-date-d-1 .csld1-col-3 .cs-secondary-btn-1 {
    width: 100%;
  }
  .cs-icon-box-1::after {
    right: 15px;
  }
  .daterangepicker .drp-calendar.left {
    border-right: 0;
  }
  .cs-homepage-sec-2 {
    margin-bottom: 30px;
    margin-top: 5px !important;
  }
  .cs-homepage-sec-3 {
    margin-bottom: 30px;
  }
  .cs-wbg-head-2 {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 25px;
  }
  .cs-wbg-head-2 span::before {
    height: 15px;
  }
  .cs-slider-row-1 .cssr1-col-1 {
    flex: 0 0 250px;
    max-width: 250px;
    margin: 0 auto;
  }
  .cs-slider-row-1 .cssr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
  }
  .cs-slider-row-1 .cssr1-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    order: 1;
  }
  .cs-slider-cont-1 .bg-image-1 {
    right: -35px;
  }
  .cs-navTabs-1 {
    justify-content: space-around;
  }
  .cs-navTabs-1.nav-tabs .nav-item {
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #707070;
  }
  .cs-navTabs-1.nav-tabs .nav-link {
    font-size: 16px;
    line-height: 24px;
    padding: 15px;
  }
  .cs-navTabs-1.nav-tabs .nav-link.active::after {
    bottom: -3px;
  }
  .cs-navTabs-content-1 {
    padding: 0 10px;
  }
  .cs-navTabs-content-1 .in-content-1 h1, .cs-navTabs-content-1 .in-content-1 h2, .cs-navTabs-content-1 .in-content-1 h3, .cs-navTabs-content-1 .in-content-1 h4, .cs-navTabs-content-1 .in-content-1 h5, .cs-navTabs-content-1 .in-content-1 h6 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .cs-navTabs-content-1 .in-content-1 p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .cs-navTabs-content-1 .in-content-1 li {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .cs-homepage-sec-5 {
    margin-bottom: 30px;
  }
  .cs-homepage-sec-5 .left-side {
    border: 0;
  }
  .cs-global-desc-1 {
    font-size: 16px;
    line-height: 24px;
  }
  .cs-homepage-sec-6 .slider-area-1 {
    padding-left: 15px;
  }
  .cs-homepage-sec-6 .header-area-1 {
    padding: 0;
  }
  .cs-slider-3.owl-theme .owl-nav {
    margin-top: 15px;
    left: -10px;
    position: relative;
    text-align: center;
    top: auto;
  }
  .cs-slider-3.owl-theme .owl-nav [class*=owl-] {
    font-size: 35px;
  }
  .cs-comment-box-1 .head-1 {
    font-size: 18px;
    line-height: 26px;
  }
  .cs-comment-box-1 .desc-1 {
    font-size: 12px;
    line-height: 20px;
  }
  .cs-comment-box-1 .desc-2 {
    font-size: 14px;
  }
  .cs-comment-box-1 .desc-3 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-comment-box-1 .link-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-comment-box-1 .bottom-area-1 .ba1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
  .cs-comment-box-1 .bottom-area-1 .ba1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-homepage-sec-7 .cs-slider-4.owl-theme .owl-nav {
    left: -10px;
    position: relative;
    text-align: center;
    top: auto;
  }
  .cs-slider-4.owl-theme .owl-nav {
    margin-top: 15px;
    left: -10px;
    position: relative;
    text-align: center;
    top: auto;
  }
  .cs-slider-4.owl-theme .owl-nav [class*=owl-] {
    font-size: 35px;
  }
  .cs-city-box-1 .image-div-1 img {
    max-height: 140px;
  }
  .cs-city-box-1 .head-1 {
    font-size: 22px;
    line-height: 30px;
  }
  .cs-city-box-1 .head-1 i {
    font-size: 12px;
  }
  .cs-download-app-row-1 .csdar1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-download-app-row-1 .csdar1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-download-app-row-1 .download-app-i-1 {
    height: 250px;
  }
  .cs-download-app-row-1 .download-app-i-1 img {
    object-fit: contain;
    max-width: 100%;
  }
  .cs-campaign-box-1 .text-div-1 .head-1 {
    font-size: 20px;
    line-height: 30px;
    max-height: 30px;
    min-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .cs-global-link-1 {
    font-size: 18px;
    line-height: 26px;
  }
  .cs-homepage-sec-8 {
    margin-bottom: 40px;
  }
  .cs-homepage-sec-9 {
    margin-bottom: 0px;
  }
  .cs-blog-content-row-1 .csbcr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-blog-content-row-1 .csbcr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-homepage-sec-10 {
    margin-bottom: 30px;
  }
  .cs-homepage-sec-10 .slider-content-1 {
    padding-top: 20px;
  }
  .cs-blog-box-1 .image-div-1 {
    background-size: cover !important;
  }
  .cs-hwl-row-1 {
    margin-bottom: 15px;
  }
  .cs-hwl-row-1 .cshwlr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-hwl-row-1 .cshwlr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-homepage-sec-11 .content-area-1 {
    padding: 0;
  }
  .cs-login-main-row-1 .cslmr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-login-main-row-1 .cslmr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-login-bg-div-1 {
    min-height: 40vh;
  }
  .cs-login-text-c-1 {
    margin: 0 auto 20px;
  }
  .cs-login-text-c-1::before {
    transform: scale(0.6);
    left: -50vw;
    top: -125px;
  }
  .cs-login-text-c-1 .text-d-1 .head-1 {
    font-size: 40px;
    line-height: 52px;
  }
  .cs-login-text-c-1 .text-d-1 .desc-1 {
    font-size: 18px;
    line-height: 30px;
  }
  .cs-login-form-c-1 {
    margin: 0 auto;
    padding: 30px 15px;
  }
  .cs-login-form-c-1 .form-d-2 .form-input-1 {
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .cs-wbg-head-3 {
    font-size: 24px;
    line-height: 38px;
  }
  .cs-wbg-head-3 span::before {
    height: 15px;
  }
  .cs-global-desc-2 {
    font-size: 18px;
    line-height: 26px;
  }
  .cs-verification-i-1 {
    margin-bottom: 20px;
  }
  .cs-verification-i-1 li {
    width: 40px;
    margin-right: 10px;
  }
  .cs-rentcar-row-1 .csrcr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 2;
    min-height: 1px;
  }
  .cs-rentcar-row-1 .csrcr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
    height: 275px;
  }
  .cs-page-list-1 {
    padding: 0 15px;
    margin: 0 auto;
  }
  .cs-page-list-1 .inner-pl-1 {
    max-height: 100vh;
  }
  .cs-page-filter-d-1 .inner-f-div-1 {
    margin: 0 auto;
    padding: 0 15px;
  }
  .cs-page-filter-d-1 .filter-mm-1 .fmm-item-1 {
    position: unset;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    left: 0px;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-middle-area-1 {
    max-height: 300px;
    min-height: 300px;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-bottom-area-1 {
    padding: 15px 30px;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 li {
    width: calc(50% - 20px);
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 li:nth-child(3n) {
    margin-right: 20px;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 li:nth-child(2n) {
    margin-right: 0px;
  }
  .cs-page-filter-d-1 .fmm-f-div-1 .fmm-val-cont-1 .fmm-item-list-1 .cs-checkbox-1 label {
    padding: 12px;
  }
  .cs-search-locDate-area-1 .inn-cont-1 {
    padding: 0px 15px;
  }
  .cs-list-row-1 .cslr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-orderSum-row-1 .csorr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 25px;
  }
  .cs-orderSum-row-1 .csorr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-orderSum-left-a-1 .package-list-1 li {
    line-height: 1;
    padding: 0 6px;
    width: 50%;
  }
  .cs-speech-info-box-1 .speech-box-container .speech-box {
    min-width: 180px;
  }
  .cs-modal-3 .modal-body {
    padding: 30px;
  }
  .cs-discount-coupon-d-1 .csdcd1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
  .cs-discount-coupon-d-1 .csdcd1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-discount-coupon-d-1 .disCo-btn-1 {
    width: 100%;
    display: none;
  }
  .cs-discount-coupon-d-1 .disCo-btn-1.active {
    display: block;
  }
  .cs-orderSum-right-a-1 .csosr-top-a-1 {
    padding: 0px 20px;
  }
  .cs-orderSum-right-a-1 .csosr-bottom-a-1 {
    padding: 15px 20px;
  }
  .cs-orderSum-right-a-1 .sum-table-1 tr td {
    font-size: 16px;
    line-height: 22px;
  }
  .cs-orderSum-right-a-1 .sum-table-2 tr td {
    font-size: 16px;
    line-height: 22px;
  }
  .cs-orderSum-right-a-1 .sum-table-2 tr td:last-child {
    font-size: 20px;
  }
  .cs-modal-4 .modal-body {
    padding: 30px;
  }
  .cs-orderSum-left-a-1 .list-div-1 .list-1 li {
    width: 100%;
  }
  .cs-speech-info-box-2 .speech-box-container .speech-box {
    min-width: 275px;
  }
  .cs-order-successful-info-div-1 {
    padding: 30px;
  }
  .cs-order-successful-cont-1 .csosc1-head-1 {
    font-size: 24px;
    line-height: 34px;
  }
  .cs-order-successful-cont-1 .csosc1-row-1 .csosc1r1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-order-successful-cont-1 .csosc1-row-1 .csosc1r1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
  .cs-order-successful-cont-1 .csosid1-c-1 {
    border: 0;
    border-bottom: 1px solid #EDEDED;
    margin-bottom: 20px;
  }
  .cs-order-successful-cont-1 .csosid1-image-div-1 {
    padding-bottom: 10px;
    max-width: 100%;
  }
  .cs-order-successful-cont-1 .csosid1-info-div-1 {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .cs-global-congratulations-d-1 .csgcd1-lottie-cont-1 {
    max-width: 200px;
  }
  .cs-global-congratulations-d-1 .csgcd1-lottie-cont-2 {
    max-width: 250px;
  }
  .cs-global-croppie-d-1 {
    width: 225px;
  }
  .cs-account-earning-d-1 {
    position: unset;
    right: auto;
    top: auto;
    margin: -25px auto 20px;
    text-align: center;
  }
  .cs-earnings-info-div-1 .cseid1-col-1 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cs-earnings-info-div-1 .cseid1-desc-1 {
    font-size: 18px;
    line-height: 26px;
  }
  .cs-earnings-info-div-1 .cseid1-head-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-rentals-info-1 .csri1-table-1 td {
    font-size: 14px;
    line-height: 22px;
    padding: 12px 20px;
  }
  .cs-rentals-info-1 .csri1-countdown-1 {
    font-size: 14px;
  }
  .cs-rentals-info-desc-1 {
    font-size: 14px;
  }
  .cs-rentals-info-desc-1::before {
    top: 0px;
  }
  .cs-rentals-info-1 .csri1-result-d-1 {
    padding: 10px;
  }
  .cs-rentals-info-1 .csri1-result-d-1 .csri1rd1-t-1 {
    font-size: 14px;
  }
  .cs-rentals-info-1 .csri1-result-d-1 .csri1rd1-t-1::before {
    width: 18px;
    height: 18px;
    top: 4px;
  }
  .cs-rentals-details-d-1 .csrdd1-table-1 td {
    font-size: 14px;
    line-height: 22px;
    padding: 12px 20px;
  }
  .cs-rentals-details-d-1 .csrdd1-countdown-1 {
    margin: 0 auto;
  }
  .cs-rentals-payment-d-1 {
    padding: 5px 15px 20px;
  }
  .cs-icon-box-2 .csib2-desc-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-icon-box-2 .csib2-desc-2 {
    font-size: 18px;
    line-height: 26px;
  }
  .cs-icon-row-1 {
    margin-bottom: 25px;
  }
  .cs-icon-row-1 .csir1-col-1 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 25px;
  }
  .cs-icon-row-1 .csir1-col-1:nth-child(2n) {
    border-right: 0;
  }
  .cs-global-table-1 tr td {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-iw-desc-2 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-iw-desc-2 i {
    font-size: 20px;
  }
  .cs-modal-4 .modal-ab-icon-b-1 {
    display: none;
  }
  .cs-rentals-details-d-1 .csrdd1-result-desc-1 {
    text-align: center;
    margin-bottom: 10px;
  }
  .cs-rentals-details-d-1 .col-12.text-right {
    text-align: center !important;
  }
  .cs-navTabs-3 {
    justify-content: space-around;
  }
  .cs-navTabs-3.nav-tabs .nav-item {
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #707070;
  }
  .cs-navTabs-3.nav-tabs .nav-link {
    font-size: 16px;
    line-height: 24px;
    padding: 15px 6px;
  }
  .cs-navTabs-3.nav-tabs .nav-link.active::after {
    bottom: -3px;
  }
  .cs-account-cars-row-1 .csacr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-cars-row-1 .csacr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-cars-row-1 .csacr1-col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-cars-search-1 {
    max-width: 100%;
  }
  .cs-account-cars-row-2 .csacr2-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-modal-5 .modal-body {
    padding: 20px;
  }
  .cs-modal-5 .cs-damage-photos-main {
    flex-wrap: wrap;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-left .cs-g-file-main {
    margin: 0 auto !important;
    width: 100%;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-modal-5 .cs-damage-photos-main .cs-damage-p-right .cs-g-file-main {
    margin: 0 auto !important;
    width: 100%;
  }
  .cs-modal-5 .cs-hasar-bolgesi-main {
    height: 480px;
  }
  .cs-cars-upload-list-1 .cscul1-col-1 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cs-cars-wp-box-1 {
    justify-content: center;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    padding: 30px 25px;
  }
  .cs-cars-wp-box-1 .cscwb1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
  .cs-cars-wp-box-1 .cscwb1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 25px;
  }
  .cs-cars-wp-row-1 .cscwpr1-col-1, .cs-cars-wp-row-1 .cscwpr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-cars-wp-table-1 tbody td {
    font-size: 14px;
    line-height: 22px;
    padding: 15px 5px;
  }
  .cs-cars-wp-table-1 thead th {
    font-size: 14px;
    line-height: 22px;
    padding: 15px 5px;
  }
  .cs-soon-top-s-1 {
    margin-bottom: 40px;
  }
  .cs-soon-top-s-1 img {
    margin-bottom: 20px;
    max-height: 100px;
  }
  .cs-soon-top-s-1 .head-1 {
    font-size: 30px;
    line-height: 38px;
  }
  .cs-soon-row-1 .cssr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .cs-soon-row-1 .cssr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-soon-head-1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  .cs-soon-desc-1 {
    font-size: 16px;
    line-height: 24px;
  }
  /************ frontend-ukbe-responsive 768px start ************/
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 {
    padding: 25px 0;
  }
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 {
    display: flex;
    padding: 10px 0;
    gap: 17px;
    flex-direction: column;
    align-items: center;
  }
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1:last-child {
    flex-direction: column-reverse;
  }
  .cs-about-us-sec-1 .cs-about-us-content-wrapper-1 .cs-about-us-content-item-1 .cs-about-us-imagebox-1 {
    flex: 1;
  }
  .cs-banner-sec-1 .cs-banner-textbox-1 {
    padding: 0 5px;
  }
  .cs-banner-sec-1 .cs-banner-textbox-1 .cs-title-1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .cs-banner-sec-1 .cs-banner-textbox-1 .cs-desc-1 {
    font-size: 24px !important;
    line-height: 32px !important;
  }
  .cs-career-sec-1 .cs-career-item-1 {
    flex-direction: column-reverse;
  }
  .cs-career-sec-1 .cs-career-item-1 .cs-career-imagebox-1 {
    flex: 1 !important;
  }
  .cs-career-sec-2 .cs-career-advert-itembox-1 {
    flex-direction: column;
    align-items: center;
  }
  .cs-career-detail-sec-1 .cs-career-detail-main-1 {
    flex-direction: column;
    padding: 0;
  }
  .cs-career-detail-sec-1 .cs-career-detail-main-1 .cs-half-banner-1 {
    width: 100%;
    border-radius: 0;
  }
  .cs-career-detail-sec-1 .cs-career-detail-main-1 .cs-career-detail-text-wrapper {
    padding: 0 15px;
    gap: 15px;
  }
  .cs-career-detail-sec-1 .cs-career-detail-main-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 {
    flex-direction: column;
    align-items: end;
    margin-bottom: 15px;
  }
  .cs-career-detail-sec-1 .cs-career-detail-main-1 .cs-career-detail-text-wrapper .cs-cd-text-top-1 .cs-item-info-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .cs-career-detail-sec-1 .cs-career-detail-main-1 .cs-career-detail-inputs-1 {
    width: 100%;
    padding: 0 15px;
  }
  .cs-popular-search {
    flex-wrap: wrap;
  }
  .cs-popular-search .cs-desc-1 {
    width: 100%;
    margin-bottom: 10px;
  }
  .cs-signal-cont-1 .cssc1-d-1 .cssc1d1-inn-1 {
    right: auto;
    left: -70%;
    transform: translateX(-50%);
    width: 300px;
  }
  .cs-signal-cont-1 .cssc1-d-1 .form-content-1 {
    max-height: 50vh;
  }
  .cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-wrapper .cs-contact-us-imagebox-1 {
    display: none !important;
  }
  .cs-contact-us-sec-1 .cs-contact-us-main .cs-contact-us-form-wrapper .cs-arabanipaylas-line {
    position: unset;
    width: auto;
    margin: 15px 10px 0px 0px;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 {
    flex-direction: column;
  }
  .cs-car-detail-sec-1 .cs-cd-infobox-1 .cs-info-status-1 {
    margin-top: 30px;
  }
  .cs-car-detail-sec-1 .cs-cd-infobox-1 .cs-cd-feature-1 {
    flex-wrap: wrap;
  }
  .cs-car-detail-sec-1 .cs-cd-infobox-1 .date-box-1 input {
    width: 100% !important;
  }
  .cs-account-m-row-1 .csamr1-col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-account-m-row-1 .csamr1-col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cs-address-box-1 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .cs-account-right-div-1 {
    padding: 25px 20px;
  }
  .cs-navTabs-2.nav-tabs {
    border: 0;
  }
  .cs-navTabs-2.nav-tabs .nav-item {
    width: 100%;
    border-bottom: 1px solid #707070;
    text-align: center;
  }
  .cs-modal-info-div-1 .icon-text-1 {
    font-size: 34px;
    margin-bottom: 20px;
  }
  .cs-modal-info-div-1 .icon-text-1 span {
    width: 75px;
    height: 75px;
  }
  .cs-address-box-1 .csab1-head-1 {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .cs-address-box-1 .csab1-desc-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-register-form-c-1 .form-d-2 .form-row {
    height: auto;
    overflow: auto;
    overflow-y: auto;
  }
  .cs-register-form-c-1 .form-d-2 .cs-step-5-wrapper .cs-rules-list-1 {
    flex-direction: column-reverse;
  }
  .cs-register-form-c-1 .cs-form-tabs-nav-1 .nav .nav-link {
    font-size: 16px !important;
    white-space: revert !important;
  }
  .cs-register-form-c-1 .cs-form-tabs-nav-1 .nav .nav-link.active {
    font-size: 16px !important;
  }
  .cs-input-image-wrapper-1 .cs-input-box-dashed-1 {
    width: 135px;
    height: 135px;
  }
  .cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .cs-image-grid-1 .cs-cd-image-item-1 {
    height: 250px;
  }
  .cs-car-detail-sec-1 .cs-cd-imagebox-1 .cs-cd-images .cs-image-grid-1 .cs-cd-image-item-2 {
    height: 130px;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 {
    gap: 45px;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 {
    order: 2;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options {
    justify-content: flex-start;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 {
    width: 90px;
    height: auto;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 .cs-title-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-car-detail-sec-1 .cs-car-detail-main-1 .cs-cd-infobox-1 .cs-cd-loan-wrapper .cs-loan-options .cs-loan-item-1 .cs-desc-1 {
    font-size: 12px;
    line-height: 20px;
  }
  .step-d-1.cs-nowidth-1 {
    padding: 0;
  }
  /************ frontend-ukbe-responsive 768px end ************/
  .cs-notification-sec-1 .cs-notification-main-1 {
    height: auto;
  }
  .cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs {
    justify-content: flex-start;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }
  .cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .nav.nav-tabs .nav-item {
    white-space: nowrap;
  }
  .cs-notification-sec-1 .cs-notification-main-1 .cs-notification-tabs-1 .tab-content .cs-notification-list-wrapper .cs-notification-list-item-1 {
    height: auto;
  }
  .cs-stop-sharing-text {
    text-align: right;
  }
  .cs-rent-list ul .cs-rent-content .cs-rent-desc {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-p-campaign-sec-1 .cs-sec-campaign-main {
    flex-direction: column;
  }
  .cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox {
    order: 2;
  }
  .cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox .cs-title1 {
    font-size: 22px;
    line-height: 30px;
  }
  .cs-p-campaign-sec-1 .cs-sec-campaign-main .cs-campaign-textbox .cs-desc1 {
    font-size: 17px;
    line-height: 25px;
  }
  .cs-no-campaign {
    margin-bottom: 50px;
  }
  .cs-no-campaign .cs-no-campaign-text {
    font-size: 15px;
    line-height: 23px;
  }
  .cs-p-blog-sec-1 {
    padding: 0;
  }
  .cs-p-blog-sec-1 .cs-blog-list-main {
    grid-template-columns: repeat(1, 1fr);
  }
  .cs-p-blog-sec-1 .cs-blog-search-area .cs-title1 {
    font-size: 25px;
    line-height: 33px;
  }
  .cs-p-blog-sec-1 .cs-blog-search-area .cs-search-box .cs-search-btn {
    font-size: 17px;
    line-height: 25px;
    padding: 12px 20px;
  }
  .cs-p-blogdetail-sec-1 .cs-blog-detail-image {
    max-width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  .cs-p-blogdetail-sec-1 .cs-blog-detail-caption {
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .cs-p-sigortavekoruma-sec-1 .cs-blog-detail-main {
    margin-bottom: 50px;
  }
  .cs-p-sigortavekoruma-sec-1 .cs-blog-detail-content {
    flex-wrap: wrap;
  }
  .cs-p-sigortavekoruma-sec-1 .cs-blog-detail-content .cs-blog-detail-caption,
  .cs-p-sigortavekoruma-sec-1 .cs-blog-detail-content .cs-blog-detail-image {
    flex: 0 0 100%;
  }
  .cs-arabani-pay-bry-1-main {
    flex-direction: column;
  }
  .cs-arabani-pay-bry-1-main .jsFileName1 {
    max-width: 200px;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cs-arabani-pay-bry-1-0-main {
    flex-direction: column;
  }
  .cs-p-arabanipaylas-sec-3 .cs-arabani-pay-bry-1-1-main {
    flex-direction: column;
  }
  .cs-syc-sec-2 {
    height: auto;
  }
  .cs-syc-sec-2 .cs-syc-2-banner {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-title-banner-1 {
    font-size: 30px;
    line-height: 38px;
  }
  .cs-syc-sec-2 .cs-syc-2-banner .cs-syc-2-banner-content .cs-desc-banner-1 {
    font-size: 20px;
    line-height: 28px;
  }
  .cs-syc-sec-3 .cs-syc-3-box-main {
    grid-template-columns: repeat(1, 1fr);
  }
  .cs-syc-sec1 .cs-syc-sec-1-title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px;
    padding: 0 15px;
  }
  .cs-syc-sec1 .cs-syc-1-main {
    justify-content: center;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn {
    justify-content: flex-end;
    padding: 0;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn .dropdown {
    width: 100%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn .dropdown .dropdown-menu {
    width: 100%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-start-btn .cs-primary-btn-1 {
    width: 100%;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox {
    padding: 0;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-main-img {
    height: auto;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-imgbox .cs-right-car-img {
    right: 0;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox {
    order: 2;
    padding: 0 15px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-1-formbox .form-input-1 {
    padding: 0;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox {
    margin-bottom: 20px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction .cs-info-message {
    right: -65px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-info-direction:hover .cs-info-message {
    right: -65px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-1 {
    font-size: 23px;
    line-height: 31px;
  }
  .cs-syc-sec1 .cs-syc-1-main .cs-syc-infobox .cs-title-2 {
    font-size: 15px;
    line-height: 23px;
  }
  .cs-syc-sec-4 {
    height: auto;
  }
  .cs-syc-sec-4 .cs-sec4-img1 {
    width: 100%;
    margin-bottom: 10px;
  }
  .cs-syc-sec-4 .cs-sec4-img2 {
    width: 100%;
    margin-bottom: 10px;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main {
    flex-direction: column;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 {
    position: relative;
    left: 0;
    bottom: 0;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-wbg-head-2, .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox1 .cs-sys-sec4-title {
    text-align: center;
    font-size: 24px;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 {
    position: relative;
    top: 0;
    right: 0;
    order: 2;
  }
  .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-wbg-head-2, .cs-syc-sec-4 .cs-syc-sec4-main .cs-sys-sec4-content .cs-sec4-textbox2 .cs-sys-sec4-title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  }
  .cs-p-vehicle-owner-main {
    flex-direction: column;
  }
  .cs-p-vehicle-owner-main .cs-p-vehicle-textbox-1 {
    flex-direction: column;
  }
  .cs-p-vehicle-owner-main .cs-textarea {
    width: 100%;
  }
  .cs-p-vehicle-owner-main .cs-textarea .cs-location-phone-area {
    flex-direction: column;
    gap: 10px;
  }
  .cs-p-vehicle-owner-main .cs-textarea .cs-name-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cs-p-vehicle-owner-main .cs-textarea .cs-name-1 .cs-super-vhc-owner {
    padding: 3px 20px;
  }
  .cs-p-vehicle-owner-main .cs-date-1 {
    justify-content: space-between;
    gap: 12px;
  }
  .cs-p-vehicle-owner-main .cs-date-1 .cs-text-1 {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-vehicle-detail-main-1 {
    flex-direction: column;
    gap: 20px;
  }
  .cs-p-vehicle-arrangement {
    padding: 0 10px;
  }
  .cs-p-vehicle-arrangement .cs-text-3 {
    padding-right: 8px;
    margin-right: 8px;
    font-size: 14px;
    line-height: 24px;
  }
  .cs-p-vehicle-arrangement .cs-text-4 {
    font-size: 13px;
    line-height: 23px;
  }
  .cs-create-mission-main {
    padding: 30px 3%;
  }
  .cs-car-reservation-calendar {
    flex-direction: column;
    padding: 30px 15px;
    gap: 10px;
    align-items: center;
  }
  .cs-car-reservation-calendar .cs-rc-left {
    display: flex;
    flex-direction: column;
  }
  .cs-mission-main-area .cs-mission-message {
    text-align: center;
  }
  .cs-profile-container-1 {
    padding: 0 15px;
  }
  .cs-start-location-modal,
  .cs-end-location-modal,
  .cs-location-modal {
    min-width: 320px;
    padding: 30px 20px;
    left: 0;
  }
  .cs-start-location-modal .cs-location-modal-list li.active a,
  .cs-end-location-modal .cs-location-modal-list li.active a,
  .cs-location-modal .cs-location-modal-list li.active a {
    width: auto !important;
  }
  .cs-start-location-modal .cs-location-modal-list li a,
  .cs-end-location-modal .cs-location-modal-list li a,
  .cs-location-modal .cs-location-modal-list li a {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-p-vehicle-arrangement {
    flex-wrap: wrap;
    gap: 10px;
  }
  .cs-p-vehicle-arrangement .cs-arrangement-button {
    padding: 12px 20px 12px 12px;
    font-size: 14px;
  }
  .cs-sorting-area-1 .cs-sorting-modal-2 {
    width: 250%;
  }
  .cs-sorting-area-1 .cs-sorting-modal-2 .cs-sort-btn-group {
    flex-direction: column;
  }
  .cs-sorting-area-1 .cs-sorting-modal-1 {
    width: 180%;
  }
  .cs-sorting-area-1 .cs-sorting-modal-1 .cs-sort-btn-group {
    flex-direction: column;
  }
  .cs-sorting-area-1 .cs-sorting-modal-3 {
    width: 260%;
  }
  .cs-sorting-area-1 .cs-sorting-modal-4 {
    width: 260%;
  }
  .cs-sorting-area-1 .cs-sorting-modal-4 .cs-sort-btn-group {
    flex-direction: column;
  }
  .cs-car-detail-main-area .cs-car-main {
    grid-template-columns: repeat(2, 1fr);
  }
  #stop_share_modal .cs-modal-btn-group {
    gap: 0;
  }
  #stop_share_modal .cs-padding-1 {
    padding: 0 5% !important;
    margin: 0 !important;
  }
  #stop_share_modal .modal-body {
    padding: 10px;
  }
  #stop_share_modal .modal-content {
    margin: 0 auto;
    width: 100%;
  }
  .cs-form-1-2 .date-box-1 label:before {
    left: 34px !important;
  }
  .cs-main-comment-area-2 {
    padding: 10px;
  }
  .cs-main-comment-area-2 .cs-star-name-text .cs-comment-name-1 {
    font-size: 14px;
  }
  .cs-main-comment-area-2 .cs-star-name-text i {
    font-size: 14px;
  }
  .cs-main-comment-area-2 .cs-star-name-text .cs-comment-date-text-1 {
    font-size: 12px;
  }
  .cs-main-comment-area-1 i {
    font-size: 16px;
  }
  .cs-main-comment-area-1 .cs-comment-area-text-1 {
    font-size: 14px;
  }
  .cs-mission-choise-main-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  #damage_area_modal .cs-hasar-bolgesi-main {
    height: 495px;
  }
  #damage_area_modal .modal-content {
    width: 320px;
    margin: 0 auto;
    min-width: 320px;
  }
  .cs-mission-choise-main-1 {
    gap: 15px;
  }
  .cs-mission-choise-1 {
    overflow: hidden;
    overflow-x: auto;
    gap: 10px;
    padding-bottom: 15px;
  }
  .cs-mission-choise-1::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F0EDFF;
  }
  .cs-mission-choise-1::-webkit-scrollbar {
    height: 2px;
  }
  .cs-mission-choise-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #7287FD;
  }
  .cs-content-item {
    white-space: nowrap;
  }
  .cs-mission-list-count {
    height: auto;
    flex-direction: column;
    padding: 10px;
    gap: 18px;
  }
  .cs-mission-list-count .cs-mission-list-item {
    justify-content: flex-start;
  }
  .cs-mission-list-count .cs-mission-list-item:before {
    content: none;
  }
  .cs-profile-detail-wp-sec-2 .cs-profile-detail-wp-left {
    padding: 25px 10px;
  }
  .cs-p-arabanipaylas-sec-2, .cs-p-arabanipaylas-sec-3, .cs-p-arabanipaylas-sec-4 {
    margin: 0;
  }
  #insurance_policy .modal-dialog {
    max-width: 360px;
  }
  #insurance_policy .modal-dialog .cs-modal-title1 {
    font-size: 17px;
  }
  #insurance_policy .modal-dialog .cs-modal-desc1 {
    font-size: 14px;
    line-height: 22px;
  }
  #insurance_policy .cs-globe-dd-1 .cs-header-btn-3 {
    max-width: 275px;
  }
  .cs-damage-photos-main {
    flex-direction: column;
  }
  .cs-damage-photos-main .cs-damage-p-left .cs-g-file-main {
    width: 100%;
  }
  .cs-damage-photos-main .cs-damage-p-right .cs-g-file-main {
    width: 100%;
  }
  #car-photo-add-modal .modal-content {
    min-width: 100%;
    width: 100%;
  }
  #damage_area_modal .modal-content {
    min-width: 100%;
    width: 100%;
  }
  .cs-car-list-main {
    margin: 0 !important;
  }
  .cs-calendar-list .cs-calendar-list-area .cs-date-list {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-rent-bill-main-2 .cs-rent-content-1 {
    justify-content: center;
  }
  .cs-rent-bill-main-2 .cs-rent-content-1 .form-check {
    padding-left: 12px;
  }
  .cs-profile-detail-wp-sec-4 .cs-pd4-main {
    gap: 25px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;
  }
  .cs-rent-list-pay .cs-pay-info-buttons {
    flex-direction: column;
  }
  .cs-mission-main-area .nav-tabs .nav-link {
    font-size: 14px;
    line-height: 22px;
  }
  .cs-mission-main-area .nav-tabs .nav-link.active::after {
    width: 145px;
  }
  .cs-download-app-2 {
    position: revert;
    margin-top: 30px;
  }
  .cs-download-app-2 .image-m-1 {
    justify-content: center;
  }
}
@media (min-width: 500px) {
  #goTpButton {
    margin: 30px;
  }
}